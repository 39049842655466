



































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import {VTextField} from 'vuetify/lib/components';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default defineComponent({
  name: 'uOttawa1321Lab2Question3',
  components: {TableInput, STextarea, CalculationInput, VTextField},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        aiQuestionResponse: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      questions: [
        {
          en: 'You are given pure samples of benzoic acid and 1,3,5-tribromobenzene. Each sample melts at 122°C. You have an unknown sample that also melts at 122°C. Without using TLC, describe a technique you could use to determine if your unknown is benzoic acid, 1,3,5-tribromobenzene or some other solid that melts at 122°C.',
          fr: 'Vous recevez des échantillons purs de l’acide benzoïque et le 1,3,5-tribromobenzène. Chaque échantillon ait un point de fusion de 122°C. Vous avez un inconnu qui a aussi un point de fusion de 122°C. Sans utiliser le CCM, décrivez une technique que vous pouvez utiliser afin de déterminer si l’inconnu est l’acide benzoïque, le 1,3,5-tribromobenzène ou un autre composé ayant un point de fusion de 122°C.',
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
